<template>
    <keep-alive v-if="list"> <userBanner :list="list"></userBanner></keep-alive>

    <main>
        <div class="sign-title">
            连续签到<span>{{continuity}}</span>天
        </div>
        <ul class="sign-list">
            <li class="sign-item ">
                <div :class="{'sign-count': true, 'signed': continuity >= 1}">
                    +30
                </div>
                <div class="sign-day">
                    第一天
                </div>
            </li>
            <li class="sign-item">
                <div :class="{'sign-count': true, 'signed': continuity >= 2}">
                    +30
                </div>
                <div class="sign-day">
                    第二天
                </div>
            </li>
            <li class="sign-item">
                <div :class="{'sign-count': true, 'signed': continuity >= 3}">
                    +30
                </div>
                <div class="sign-day">
                    第三天
                </div>
            </li>
            <li class="sign-item">
                <div :class="{'sign-count': true, 'signed': continuity >= 4}">
                    +30
                </div>
                <div class="sign-day">
                    第四天
                </div>
            </li>
            <li class="sign-item">
                <div :class="{'sign-count': true, 'signed': continuity >= 5}">
                    +30
                </div>
                <div class="sign-day">
                    第五天
                </div>
            </li>
        </ul>
        <div class="sign-button to-sign" v-if="!sl" @click="handleSign">
            签到
        </div>
        <div class="sign-button signed-button" v-else>
            已签到
        </div>

        <div class="task-wapper">
            <div class="sign-title">
                我的任务
            </div>
            <ul class="task-list">
                <li class="task-item">
                    <!-- <img src="https://www.bzwz.com/static/images/myTaskJifen.png" alt=""> -->
                    <img src="@/assets/img/mine/myTaskJifen.png" alt="">
                    +300
                    <span class="task-name">完善个人资料</span>
                    <router-link :to="{name: 'UserInfo'}" class="task-status to-completed" v-if="result.isComplete == 0">
                      去完成
                    </router-link>
                    <a  href="javascript: void(0);" v-else class="task-status">
                        已完成
                    </a>
                </li>
                <!--<li class="task-item">
                    &lt;!&ndash; <img src="https://www.bzwz.com/static/images/myTaskJifen.png" alt=""> &ndash;&gt;
                    <img src="@/assets/img/mine/myTaskJifen.png" alt="">
                    +100
                    <span class="task-name">完善地址</span>
                    <router-link :to="{name: 'Address'}" class="task-status to-completed" v-if="result.isAddress == 0">
                      去完成
                    </router-link>
                    <a href="javascript: void(0);" v-else class="task-status">
                        已完成
                    </a>
                </li>-->
                <li class="task-item">
                    <!-- <img src="https://www.bzwz.com/static/images/myTaskJifen.png" alt=""> -->
                    <img src="@/assets/img/mine/myTaskJifen.png" alt="">
                    +100
                    <span class="task-name">发布帖子</span>
                    <router-link :to="{name: 'PostTopic'}" class="task-status to-completed">
                      去发布
                    </router-link>
                </li>
            </ul>
        </div>
    </main>
</template>

<script lang="ts">
import { defineComponent, ref, reactive } from "vue";
// import { useRouter } from "vue-router";

//  utils
import api from "@/api/axios";
import Toast from "@/components/UI/Toast/";
import userBanner from "@/components/common/UserBanner.vue";


export default defineComponent({
  name: "signIn",
  components: {
      userBanner
  },
  setup() {
    const continuity = ref(0);
    const sl = ref({});
    const result = ref({});
    const list = ref(null);
    api.get("/M/User").then((res): void => {
      list.value = res.data.obj.orderBanners;
    });
    function getSignin(){
      api.get("/M/Home/SignIn").then(res => {
        const data = res.data.obj;
        continuity.value = data.continuity;
        sl.value = data.sl;
        result.value = data;
      }).catch(err => {
        console.log(err)
      })
    }

    getSignin();

    function handleSign() {
      if(!sl.value){
        api.post("/M/BBSServer/SignIn").then(res => {
          const data = res.data;
          Toast({
            title: data.msg,
            type: "success"
          })
          // sl.value = data.sl;
          // continuity.value = data.continuity;
          getSignin();
        }).catch(err => {
          console.log(err)
        })
      }
    }

    return {
      continuity,
      handleSign,
      sl,
      result,
      list
    };
  }
});
</script>
<style lang="scss">
    .swiper-container{
        margin-bottom: 11px;
    }
</style>
<style lang="scss" scoped>
main{
  padding-bottom:2.933rem;
}

.sign-title{
  font-size: 0.44rem;
  height: 0.667rem;
  line-height: 0.667rem;
  color: #333;
  padding-left: 0.267rem;
  margin-left: 0.4rem;
  box-sizing: border-box;
  position: relative;
}

.sign-title::after{
  content: "";
  display: block;
  position: absolute;
  left: -0.067rem;
  top: 0.107rem;
  width: 4px;
  height: 0.467rem;
  border-radius: 0.267rem;
  background: #d64b4b;
}

.sign-list{
  text-align: center;
  margin-top: 0.4rem;
}

.sign-item{
  display: inline-block;
  margin: 0.267rem;
  position: relative;
}

.sign-list li:nth-child(n+2)::after {
  content: "";
  display: block;
  position: absolute;
  width: 0.4rem;
  height: 1px;
  background: #f1f1f1;
  top: 0.533rem;
  left: -0.533rem;
}

.sign-count{
  display: inline-block;
  width: 1.067rem;
  height: 1.067rem;
  border-radius: 50%;
  background: #f1f1f1;
  color: #666;
  line-height: 1.133rem;
  text-align: center;
  font-size: 0.373rem;
}

.sign-item .signed{
  background: #d64b4b ;
  color: #fff ;
}



.sign-day{
  margin-top: 0.267rem;
  font-size: 0.32rem;
  color: #666;
}


.sign-button{
  width: 3.333rem;
  height: 0.933rem;
  background-color: #d64b4b;
  box-shadow: 0px 0.067rem 0.2rem 0px rgba(0, 0, 0, 0.15);
  border-radius: 0.6rem;
  line-height: 0.933rem;
  text-align: center;
  color: #fff;
  font-size: 0.44rem;
  margin: 0 auto;
  margin-top: 0.533rem;
  margin-bottom: 0.533rem;
}

.signed-button{
  background: #bfbfbf;
}


.task-wapper{

    border-top: 0.16rem solid #f4f4f4;
    padding: 0.4rem 0;
}

.task-item{
  padding: 0.4rem;
  border-bottom: 1px solid #e5e5e5;
  font-size: 0.4rem;
  color: #d64b4b;
  position: relative;
}

.task-item img{
  width: 0.533rem;
  height: 0.533rem;
  vertical-align: middle;
}

.task-name{
  font-size: 0.4rem;
  display: inline-block;
  margin-left:  0.067rem;
  font-weight: bold;
  color: #333;
}

.task-status{
  position: absolute;
  right: 0.8rem;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
  display: block;
  width: 1.6rem;
  height: 0.667rem;
  line-height:  0.667rem;
  text-align: center;
  border-radius: 0.533rem;
  cursor: pointer;
  border: 1px solid #bfbfbf;
  color: #bfbfbf ;
  font-size: 0.32rem;
}

.to-completed {
    color: #d64b4b;
    border-color: #d64b4b;
}
</style>
