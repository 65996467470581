
import { defineComponent, ref, reactive } from "vue";
// import { useRouter } from "vue-router";

//  utils
import api from "@/api/axios";
import Toast from "@/components/UI/Toast/";
import userBanner from "@/components/common/UserBanner.vue";


export default defineComponent({
  name: "signIn",
  components: {
      userBanner
  },
  setup() {
    const continuity = ref(0);
    const sl = ref({});
    const result = ref({});
    const list = ref(null);
    api.get("/M/User").then((res): void => {
      list.value = res.data.obj.orderBanners;
    });
    function getSignin(){
      api.get("/M/Home/SignIn").then(res => {
        const data = res.data.obj;
        continuity.value = data.continuity;
        sl.value = data.sl;
        result.value = data;
      }).catch(err => {
        console.log(err)
      })
    }

    getSignin();

    function handleSign() {
      if(!sl.value){
        api.post("/M/BBSServer/SignIn").then(res => {
          const data = res.data;
          Toast({
            title: data.msg,
            type: "success"
          })
          // sl.value = data.sl;
          // continuity.value = data.continuity;
          getSignin();
        }).catch(err => {
          console.log(err)
        })
      }
    }

    return {
      continuity,
      handleSign,
      sl,
      result,
      list
    };
  }
});
